<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Admin panel</p>
                    <CInput
                        placeholder="Email"
                        type="email"
                        autocomplete="email"
                        v-model="$v.auth_user.email.$model"
                        :is-valid="$v.auth_user.email.$dirty ? (!$v.auth_user.email.$error) : null"
                    >
                      <template #prepend-content>
                        <CIcon name="cil-user"/>
                      </template>
                    </CInput>
                  <pre>
                  </pre>
                  <CInput
                      placeholder="Password"
                      type="password"
                      v-model="$v.auth_user.password.$model"
                      autocomplete="curent-password"
                      :is-valid="$v.auth_user.password.$dirty ? (!$v.auth_user.password.$error) : null"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton @click="doLogin" :disabled="$v.auth_user.$invalid" color="primary" class="px-4">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CToaster :autohide="2000" v-if="successMessage.message">
      <template >
        <CToast
            :show="true"
            header="Info"
            :color="successMessage.type"
        >
          {{ successMessage.message }}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import {mapActions} from "vuex"
import {email, required, minLength} from "vuelidate/lib/validators";

export default {
  name: 'Login',
  data() {
    return {
      auth_user: {
        email: '',
        password: ''
      },
      successMessage: {
        message: '',
        type: ''
      }
    }
  },
  validations: {
    auth_user: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    ...mapActions(['loginUserAction']),
    doLogin() {
      this.successMessage = {message: '', type: ''}
      this.loginUserAction(this.auth_user).then(() => {
        this.successMessage.message = 'You are logged in'
        this.successMessage.type = 'success'
        this.$router.push({
          name: 'Home'
        })
      }).catch(e => {
        this.successMessage.message = e.response.data.email ? e.response.data.email[0] :  e.response.data.password ? e.response.data.password[0] : 'Something went wrong'
        this.successMessage.type = 'danger'
      }).finally(() => {
      })
    }
  }
}
</script>
